<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>Contact</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      placeholder="Search"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          New Contact
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listEmployeeContact.length >0">
          <thead>
          <tr>
            <th>

            </th>
            <th class="text-left">
              ContactTye
            </th>
            <th class="text-left">
              ContactName
            </th>
            <th class="text-left">
              Created
            </th>
            <th class="text-left">
              Updated
            </th>
            <th class="text-left">

            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listEmployeeContact" :key="idx">
            <td class="text-center">{{ idx + 1 }}</td>
            <td>
              <div class="contact-employee" v-if="item.contact_type_name == 'Facebook'">
               <div class="facebook">
                 <i class="fab fa-facebook"></i>
               </div>
              </div>

              <div class="contact-employee" v-if="item.contact_type_name == 'Email'">
               <div class="email">
                 <i class="fal fa-envelope"></i>
               </div>
              </div>

              <div class="contact-employee" v-if="item.contact_type_name == 'Phone'">
                <div class="phone">
                  <i class="fal fa-phone-alt"></i>
                </div>
              </div>

              <div class="contact-employee" v-if="item.contact_type_name == 'Whatsapp'">
                <div class="whatsapp">
                  <i class="fab fa-whatsapp"></i>
                </div>
              </div>

            </td>
            <td>
              {{ item.value }}
            </td>
            <td>
              {{ item.created_at }}
            </td>
            <td>
              {{ item.updated_at }}
            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>

        <Loading v-if="isLoading"/>

        <Pagination
            class="mt-5 mb-5"
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchEmployeeContact"
        >
        </Pagination>
        <ModalEdit fullscreen title="EditEmployee">
          <template v-slot="{close}">
            <editContact :employeeContact="employeeContact" @close="close" @success="fetchEmployeeContact"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <DeleteContact :employeeContactId="employeeContactId" @close="close" @success="fetchEmployeeContact"/>
          </template>
        </ModalDelete>

      </div>
    </div>
  </div>
</template>

<script>
import editContact from "../../../components/employeeContact/from/editContact";
import DeleteContact from "../../../components/employeeContact/from/DeleteContact";
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    editContact,
    DeleteContact,
    Pagination,
    Loading,
    defaultTableNoResult
  },
  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: false,
      listEmployeeContact: [],
      employeeContact: {},
      employeeContactId: "",
    }
  },
  methods: {
    searchFilterItem() {
      this.fetchEmployeeContact();
    },
    filterEmployeeContact(employeeContactId) {
      return (
          this.listEmployeeContact.filter((item) => {
            return item.id == employeeContactId;
          })[0] || {}
      );
    },

    onCreate() {
      this.$router.push({name: 'contact.create'}).catch(() => {
      });
    },

    onEdit(employeeContactId) {
      this.employeeContact = {
        ...this.filterEmployeeContact(employeeContactId)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(employeeContactId) {
      this.employeeContactId = employeeContactId;
      this.$store.commit("modalDelete_State", true);
    },


    fetchEmployeeContact() {
      this.isLoading = true;
      this.$axios.get(`employee/contact`, {
            params: {
              page: this.pagination.current_page,
              per_page: this.per_page,
              filter: this.searchItem,
            }
          }
      ).then((res) => {
        if (res.data.code === 200) {
          this.listEmployeeContact = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listEmployeeContact.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;
      }).catch(() => {
      })
    }
  },
  created() {
    this.fetchEmployeeContact();
  }
}
</script>

<style scoped lang="scss">
.contact-employee {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;


  .facebook{
    i {
      font-size: 30px;
      border-radius: 50%;
      color: #0978EA;
    }
  }
  .email, .phone{
    i {
      font-size: 30px;
      border-radius: 50%;
      color: #719AFF;
    }
  }

  .whatsapp{
    i{
      font-size: 30px;
      border-radius: 50%;
      color: rgba(39, 174, 96,1.0);
    }
  }


}
</style>
